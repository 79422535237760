﻿import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import $ from "jquery";
import "jquery-blockui";
//import Bulma from '@vizuaalog/bulmajs';

$(document).ready(function () {

    //init navbar
    // Check for click events on the navbar burger icon
    $(".navbar-burger").click(function () {
        //console.log("nav")
        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        $(".navbar-burger").toggleClass("is-active");
        $(".navbar-menu").toggleClass("is-active");

    });

    window.Stimulus = Application.start();
    const context = require.context("./controllers", true, /\.js$/);
    Stimulus.load(definitionsFromContext(context));

    //loading overlay defaults
    $.blockUI.defaults.css.padding = 10;
    $.blockUI.defaults.message = $("#loadingOverlayContainer").html();
});



