﻿import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static get targets() {
        return [
            "lblHelpText",
        ]
    }

    initialize() {
        console.log("initialize");
    }

    connect() {
        console.log("connect");
    }

    earlier(event) {        
        this.lblHelpTextTarget.innerHTML = "Drop off time is same as pickup time.";
    }

    later(event) {        
        this.lblHelpTextTarget.innerHTML = "Drop off time is later than pickup time.";
    }
}